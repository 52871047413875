






import { Component, Vue } from "vue-property-decorator";
import Summary from "@/components/Summary.vue";

@Component({
  components: {
    Summary,
  },
})
export default class Home extends Vue {}
