import axios from "axios";

export default class BaseService {
  endpoint: string;
  baseUrl = process.env.VUE_APP_API_URL || "/devapi/";

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  protected getUrl() {
    return `${this.baseUrl}${this.endpoint}`;
  }

  async getAll() {
    return axios.get(this.getUrl(), this.allParam()).then((resp) => {
      return resp.data.data;
    });
  }

  async getCount() {
    return axios.get(this.getUrl(), this.countParam()).then((resp) => {
      return resp.data.meta.count;
    });
  }

  async getList(page?: number) {
    return axios.get(this.getUrl(), this.pageParam(page)).then((resp) => {
      return resp.data.data;
    });
  }

  async get(id: number) {
    return axios.get(this.getUrl() + `/${id}`).then((resp) => {
      return resp.data.data;
    });
  }

  async create(body: any, accessToken) {
    return axios
      .post(this.getUrl(), body, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data;
      });
  }

  async update(id: number, body: any, accessToken) {
    return axios
      .put(this.getUrl() + `/${id}`, body, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data;
      });
  }

  async delete(id: number, accessToken) {
    return axios
      .delete(this.getUrl() + `/${id}`, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data;
      });
  }

  protected buildParams(params?: Map<string, any>) {
    if (!params) {
      return {};
    }
    const result = new URLSearchParams();

    for (const [key, value] of params) {
      if (value) {
        result.append(key, value);
      }
    }
    return { params: result };
  }

  private pageParam(page?: number) {
    return this.buildParams(new Map([["page", page]]));
  }

  private allParam() {
    return this.buildParams(new Map([["all", true]]));
  }

  private countParam() {
    return this.buildParams(new Map([["count", true]]));
  }

  private getAuthorization(accessToken: string) {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }
}
