<template>
  <v-snackbar v-model="snackbar">
    {{ snackbarText }}
    <v-btn color="pink" text @click="dismiss">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { bus } from "@/main";

export default {
  data: () => ({
    snackbar: false,
    snackbarText: "",
  }),
  methods: {
    dismiss() {
      this.snackbar = false;
    },
  },
  created() {
    bus.$on("messageBar", (text) => {
      this.snackbarText = text;
      this.snackbar = true;
    });
  },
};
</script>
