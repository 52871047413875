import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/ps2",
    name: "PS2",
    component: () => import(/* webpackChunkName: "ps2" */ "../views/PS2.vue"),
  },
  {
    path: "/ps2protected",
    name: "PS2protected",
    component: () => import(/* webpackChunkName: "ps2" */ "../views/PS2.vue"),
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
