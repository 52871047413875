




























import { Component, Vue } from "vue-property-decorator";
import PS2Service from "@/services/PS2Service";

@Component
export default class Summary extends Vue {
  public ps2count = 0;
  public ps2DupeCount = 0;

  get total(): number {
    return this.ps2count + this.ps2DupeCount;
  }

  created() {
    this.getGameCount();
  }

  async getGameCount() {
    PS2Service.getAllPS2Count().then((count) => {
      this.ps2count = count;
    });
    PS2Service.getDuplicates().then((result) => {
      this.ps2DupeCount = result.meta.count;
    });
  }
}
