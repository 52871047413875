













import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Snackbar from "@/components/Snackbar.vue";

@Component({
  components: {
    Header,
    Snackbar,
  },
})
export default class App extends Vue {}
