import Vue from "vue";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { RawLocation } from "vue-router";
import { Auth0Plugin } from "./auth";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import ToggleButton from "vue-js-toggle-button";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import './assets/css/main.css';
import VModal from "vue-js-modal";
import VueConfirmDialog from "vue-confirm-dialog";
import VuePictureSwipe from "vue-picture-swipe";
import VueCookie from "vue-cookie";

export const bus = new Vue();

const dateFormatter = new Intl.DateTimeFormat("en-CA");
const currencyFormatter = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENTID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  onRedirectCallback: (appState: { targetUrl: RawLocation }) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(VueLodash, { name: "custom", lodash: lodash });

Vue.use(ToggleButton);
Vue.use(VueMaterial);
Vue.use(VModal);
Vue.use(VueConfirmDialog);
Vue.use(VueCookie);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.component("vue-picture-swipe", VuePictureSwipe);

Vue.mixin({
  methods: {
    dateFormat: (date) => {
      return date ? dateFormatter.format((new Date(date))) : null;
    },
    currencyFormat: (number) => {
      return currencyFormatter.format(number ?? 0);
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
