import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

export interface State {
  userPermissions: string[];
}

const vuexLocalStorage = new VuexPersistence({
  key: "ps2tlrmcknz",
  storage: window.localStorage,
  reducer: (state: State) => ({
    userPermissions: state.userPermissions,
    // If needed, put mutation filters here
  }),
});

export default new Vuex.Store<State>({
  plugins: [vuexLocalStorage.plugin],
  state: {
    userPermissions: [],
  },
  mutations: {
    updateSessionInfo(state, payload) {
      state.userPermissions = payload.userPermissions;
    },
    deleteSessionInfo(state) {
      state.userPermissions = [];
    },
  },
  actions: {
    setSessionInfo(context, payload) {
      context.commit("updateSessionInfo", payload);
    },
    deleteSessionInfo(context) {
      context.commit("deleteSessionInfo");
    },
  },
  getters: {
    getUserPermissions(state) {
      return state.userPermissions;
    },
  },
});
