
































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  private isDarkMode = true;

  created() {
    this.isDarkMode = (this.$cookie.get("dark-mode") ?? "true") === "true";
    this.$vuetify.theme.dark = this.isDarkMode;
  }

  darkMode() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    this.$cookie.set("dark-mode", this.$vuetify.theme.dark);
  }

  login() {
    this.$auth.loginWithRedirect();
  }

  logout() {
    this.$auth.logout({
      returnTo: window.location.origin,
    });
  }
}
