import axios from "axios";
import BaseService from "./BaseService";

class PS2Service extends BaseService {
  constructor() {
    super("ps2");
  }

  async getPS2(id: number) {
    return this.get(id).then((resp) => {
      return resp;
    });
  }

  async getPS2List(page?: number) {
    return this.getList(page).then((resp) => {
      return resp;
    });
  }

  async getAllPS2() {
    return this.getAll().then((resp) => {
      return resp;
    });
  }

  async getDuplicates(gameId?: number) {
    const params: Map<string, any> = new Map([["duplicates", true]]);
    if (gameId) {
      params.set("game_id", gameId);
    }
    return axios.get(this.getUrl(), this.buildParams(params)).then((resp) => {
      return resp.data;
    });
  }

  async getAllPS2Count() {
    return this.getCount().then((resp) => {
      return resp;
    });
  }
}

export default new PS2Service();
